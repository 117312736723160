import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout.jsx";
import ContactFlow from '../components/ContactFlow';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Heading = makeShortcode("Heading");
const ExternalLink = makeShortcode("ExternalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Heading frontmatter={props.pageContext.frontmatter} mdxType="Heading" />
    <p>{`Hello, always happy to have a discussion, but first, a little about yourself...`}</p>
    <ContactFlow mdxType="ContactFlow" />
    <p>{`You can often find Tekhaus in the Mechanic Slack, along with over 800 other merchants and developers. There's an invite link on `}<ExternalLink href="https://mechanic.dev/" mdxType="ExternalLink">{`Mechanic's home page`}</ExternalLink>{`. Come join the community.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      